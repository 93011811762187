:root {
	--ov_foreground_color: #000000;
	--ov_background_color: #ffffff;
	--ov_disabled_foreground_color: #cccccc;
	--ov_button_color: #556ee6; /*vinx edit*/
	--ov_button_hover_color: #485ec4; /*vinx edit*/
	--ov_button_text_color: #ffffff;
	--ov_outline_button_color: #556ee6; /*vinx edit*/
	--ov_outline_button_hover_color: #c9e5f8;
	--ov_outline_button_text_color: #556ee6; /*vinx edit*/
	--ov_icon_color: #263238;
	--ov_light_icon_color: #838383;
	--ov_selected_icon_color: #556ee6; /*vinx edit*/
	--ov_disabled_icon_color: #cccccc;
	--ov_hover_color: #c9e5f8;
	--ov_hover_text_color: #556ee6; /*vinx edit*/
	--ov_logo_text_color: #15334a;
	--ov_logo_border_color: #000000;
	--ov_toolbar_background_color: #f5f5f5;
	--ov_toolbar_selected_color: #e1e1e1;
	--ov_toolbar_separator_color: #cccccc;
	--ov_treeview_selected_color: #eeeeee;
	--ov_dialog_foreground_color: #000000;
	--ov_dialog_background_color: #ffffff;
	--ov_dialog_control_border_color: #e1e1e1;
	--ov_border_color: #dddddd;
	--ov_shadow: 0px 0px 10px #cccccc;

	--ov_foreground_color_dark: #fafafa;
	--ov_background_color_dark: #222736; /*vinx edit*/
	--ov_disabled_foreground_color_dark: #888888;
	--ov_button_color_dark: #556ee6; /*vinx edit*/
	--ov_button_hover_color_dark: #485ec4; /*vinx edit*/
	--ov_button_text_color_dark: #ffffff;
	--ov_outline_button_color_dark: #c9e5f8;
	--ov_outline_button_hover_color_dark: #2a3ba5; /*vinx edit*/
	--ov_outline_button_text_color_dark: #c9e5f8;
	--ov_icon_color_dark: #fafafa;
	--ov_light_icon_color_dark: #bababa;
	--ov_selected_icon_color_dark: #556ee6; /*vinx edit*/
	--ov_disabled_icon_color_dark: #888888;
	--ov_hover_color_dark: #7387db; /*edit*/
	--ov_hover_text_color_dark: #fafafa;
	--ov_logo_text_color_dark: #fafafa;
	--ov_logo_border_color_dark: #2a2b2e;
	--ov_toolbar_background_color_dark: #2a3042; /*vinx edit*/
	--ov_toolbar_selected_color_dark: #384166; /*vinx edit*/
	--ov_toolbar_separator_color_dark: #888888;
	--ov_treeview_selected_color_dark: #384166; /*vinx edit*/
	--ov_dialog_foreground_color_dark: #fafafa;
	--ov_dialog_background_color_dark: #32394e; /*vinx edit*/
	--ov_dialog_control_border_color_dark: #e1e1e1;
	--ov_border_color_dark: #43495e; /*vinx edit*/
	--ov_shadow_dark: 0px 0px 10px #222222;
}
